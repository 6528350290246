<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1 class="sm mb-4">
        {{ translations.tcAddEditEducationalUnit }}
      </h1>
      <section class="section-1 bg-block mb-4" style="padding:20px;">
        <div class="body">
          <b-form-group>
            <b-row>
              <b-col sm="4">
                <div style="font-weight: 600">
                  {{ translations.tcClickEducationalUnitToEdit }}
                </div>
                <b-form-select
                  v-model="selected_eu"
                  :options="options_members"
                  :select-size="8"
                  @change="handleEducationalUnitSelect"
                ></b-form-select>
                <b-button
                  :name="`memberFindButton`"
                  variant="primary"
                  class="flex-1 btn-pl-xsm mt-2"
                  @click="showModal($event, 'personSearchModal')"
                  >{{ translations.tcFind }}</b-button
                >
              </b-col>
              <b-col sm="8">
                <b-row>
                  <b-col sm="12">
                    <h2 class="educationalUnit">{{ selected_candidate.name }}</h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" style="">
                    <strong>{{ translations.tcDateCertified }}</strong>
                    <a
                      v-if="!!selected_candidate.commissioned_date"
                      href="#"
                      class="ml-2 mb-3"
                      @click.prevent="handleClearClick"
                    >
                      {{ translations.tcClear }}
                    </a>
                    <b-form-datepicker
                      id="startDate"
                      v-model="selected_candidate.commissioned_date"
                      class="mb-2 contact_date"
                      :locale="prefCulture"
                      :label-help="translations.tcCalHelp"
                      :label-no-date-selected="translations.tcDatePickerPlaceholder"
                      :placeholder="translations.tcDatePickerPlaceholder"
                      :show-decade-nav="showDecadeNav"
                      :hide-header="hideHeader"
                    >
                      <template #button-content style="padding: 12px">
                        <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                      </template>
                    </b-form-datepicker>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <strong>{{ translations.tcComments }}</strong>
                    <b-form-textarea
                      id="textarea"
                      v-model="selected_candidate.ixa_note"
                      rows="2"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      style="margin-top: 20px"
                      @click="handleSaveClick"
                      variant="primary"
                      :disabled="!saveButtonEnabled"
                      class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                    >
                      {{ translations.tcSave }}
                    </b-button>
                    <b-button
                      style="margin-top: 20px"
                      @click="handleDeleteClick"
                      variant="primary"
                      :disabled="!removeButtonEnabled"
                      class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                    >
                      {{ translations.tcRemove }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
      </section>
    </page-body>
    <PersonSearch
      @selectionMade="applyFoundPerson($event)"
      :camp_country="camp_country"
      :modal_title="modal_title"
      :member_type_key="gideonMemberTypeKey"
      :use_org_not_camp="use_org_not_camp"
    >
    </PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import pageBody from '@/components/page-components/PageBody.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { calendarMixin } from '@/mixins/calendarMixin'

export default {
  name: 'addedit-educational-unit',
  mixins: [translationMixin, calendarMixin],
  data() {
    return {
      empty_candidate: {
        ind_key: '',
        name: '',
        sort_name: '',
        ixa_key: null,
        camp_name: '',
        ixa_note: null,
        ixa_start_date: null,
        ind_last_name: '',
        ind_first_name: '',
        commissioned_date: null,
        ixa_delete_flag: 0,
        ixo_key: null
      },
      findButton: '',
      hideHeader: true,
      modal_title: 'Individual Search',
      searchedMembers: [],
      secured_eu_delete_controls: {
        delete_eu_button: 'c4fa0734-0a9b-428f-96bb-02833d329aef'
      },
      secured_eu_edit_controls: {
        edit_eu_button: '70da6484-25f2-448f-906b-6b73c091f795'
      },
      selected_candidate: {},
      selectedCandidateInitial: null,
      selected_eu: null,
      showDecadeNav: true,
      successful_add: true,
      translations: {},
    }
  },
  async created() {
    try {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getViewTranslations(),
        this.getComponentTranslations('pageBody')
      ]).then(results => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)
        //this.loadTranslations()
        this.selected_candidate = this.empty_candidate
        this.page_load()
      })
    } catch (e) {
      console.error(e)
      this.setLoadingStatus(false)
    } finally {
    }
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      educational_units: 'conversations/educational_units',
      officerToolbarSelected: 'user/officerToolbarSelected',
      officerToolbarCamps: 'user/officerToolbarCamps',
      otfbCountry: 'conversations/otfbCountry',
      prefCulture: 'user/userPreferredCulture',
      state_members: 'conversations/state_members',
      userLanguageKey: 'user/userLanguageKey'
    }),
    camp_country() {
      //this.otfbCountry.cnt_key is used if the user is an IO country
      if (Object.keys(this.otfbCountry).length > 0 && this.otfbCountry.cnt_key) {
        return this.otfbCountry.cnt_key
      }
      return null
    },
    gideonMemberTypeKey() {
      return constantData.member_types[constantData.member_types.map(m => m.text).indexOf('Gideon')].value
    },
    options_members() {
      const stmems = this.state_members.map(member => {
        return {
          value: member.vin_ind_key,
          text: member.vin_complete_name
        }
      })

      return [...stmems, ...this.searchedMembers]
    },
    removeButtonEnabled() {
      return !!this.selectedCandidateInitial && !!this.selectedCandidateInitial.commissioned_date
    },
    saveButtonEnabled() {
      return !!this.selected_candidate.name && !!this.selected_candidate.commissioned_date
    },
    use_org_not_camp() {
      //this.otfbCountry.cnt_key is used if the user is an IO country
      if (Object.keys(this.otfbCountry).length > 0 && this.otfbCountry.org_key) {
        return this.otfbCountry.org_key
      } else if (this.officerToolbarCamps.length === 0) {
        return this.officerToolbarSelected.country_state
      } else {
        //we need a camp so grab the first on in the officer toolbar list
        return this.officerToolbarCamps[0].value
      }
    }
  },
  methods: {
    ...mapActions({
      addInstructorEU: 'conversations/addInstructorEU',
      loadCountryByOFTB: 'conversations/loadCountryByOFTB',
      loadEducationalUnits: 'conversations/loadEducationalUnits',
      loadStateMembers: 'conversations/loadStateMembers',
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    async page_load() {
      try {
        const payload = {
          org_key: this.officerToolbarSelected.country_state,
          lang_key: this.userLanguageKey,
          membertype_key: this.gideonMemberTypeKey
        }

        await Promise.all([
          this.setLoadingStatus(true),
          this.loadStateMembers(payload),
          this.loadEducationalUnits(payload),
          this.loadCountryByOFTB(payload),
          (this.presetDate = null)
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async applyFoundPerson(evt) {
      if (evt) {
        // if the person is not in the list of state members, add them
        if (!this.state_members.find(sm => sm.vin_ind_key === evt.ind_key)) {
          this.searchedMembers.push({
            value: evt.ind_key,
            text: evt.name_text
          })
        }
        this.selected_eu = evt.ind_key
        this.handleEducationalUnitSelect(evt)
      }
    },
    async handleDeleteClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkayToRemoveEducationalUnit,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel
      }).then(result => {
        if (result.value) {
          this.selected_candidate.ixa_delete_flag = true
          this.handleSaveClick()
        }
      })
    },
    handleClearClick() {
      this.selected_candidate.commissioned_date = null
    },
    async handleEducationalUnitSelect(searchMember) {
      const educationalUnitInfo = this.educational_units.find(cf => cf.ind_key === this.selected_eu)
      const memberInfo = this.state_members.find(cf => cf.vin_ind_key === this.selected_eu)
      this.selected_candidate = {
        ind_key: this.selected_eu,
        name: !!memberInfo ? memberInfo.vin_complete_name : searchMember.name_text,
        sort_name: !!memberInfo ? memberInfo.sort_name : searchMember.name_text,
        ixa_key: !!educationalUnitInfo ? educationalUnitInfo.ixa_key : null,
        camp_name: '',
        ixa_note: !!educationalUnitInfo ? educationalUnitInfo.ixa_note : null,
        ixa_start_date: !!educationalUnitInfo ? educationalUnitInfo.ixa_start_date : null,
        ind_last_name: !!educationalUnitInfo ? educationalUnitInfo.ind_last_name : null,
        ind_first_name: !!educationalUnitInfo ? educationalUnitInfo.ind_first_name : null,
        commissioned_date: !!educationalUnitInfo ? educationalUnitInfo.ixa_start_date : null,
        ixa_delete_flag: 0,
        ixo_key: null
      }
      // take snapshot of object so we can do a diff later
      this.selectedCandidateInitial = { ...this.selected_candidate }
    },
    async handleSaveClick() {
      await this.setLoadingStatus(true)
      let payload = {
        ...this.selected_candidate,
        ixa_acr_key: constantData.ConversationGuids.EducationalUnitAccreditation,
        org_key: this.officerToolbarSelected.country_state,
        lang_key: this.userLanguageKey
      }
      payload.ixa_start_date = this.selected_candidate.commissioned_date

      this.successful_add = await this.addInstructorEU(payload)
      if (this.successful_add) {
        this.selected_candidate = this.empty_candidate
        await this.page_load()
      }
      this.setLoadingStatus(false)
      await this.$swal({
        icon: this.successful_add ? 'success' : 'error',
        text: this.successful_add ? this.translations.tcEditSuccessful : this.translations.tcEditFailed,
        confirmButtonText: this.translations.tcOk
      })
      if (this.successful_add) {
        this.$router.push('/programs/pw/conversations/educational-units')
      }
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      switch (this.findButton) {
        case 'memberFindButton':
          this.modal_title = this.translations.tcMemberSearch
          break
        case 'nonmemberFindButton':
          this.modal_title = this.translations.tcNonMemberSearch
          break
        default:
          this.modal_title = this.translations.tcIndividualSearch
          break
      }
      this.$root.$emit('bv::show::modal', `${modalName}`)
    }
  },
  mounted() {},
  components: {
    pageBody: pageBody,
    PersonSearch: PersonSearch
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.educationalUnit {
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  margin: 20px 0;
}

.selected_blue {
  background-color: #003946;
  color: #ffffff;
}

.candidates {
  line-height: 34px;
}

.candidate_list {
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 1em;
  border: 1px solid #000;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.contact_date {
  width: 55%;
  @include breakpoint(sm) {
    width: 100%;
  }
}
</style>
